import aos from 'aos'

const mediaQueryMotion = window.matchMedia('(prefers-reduced-motion: reduce)')
const desktopDivision = 1230

export function aosInitiate() {
  aos.init({
    once: true,
    disable: window.innerWidth < desktopDivision || !mediaQueryMotion,
  })
}
