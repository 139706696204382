import {
  h,
  App,
  SetupContext,
  VNode,
} from 'vue'

import { i18n } from '@/i18n'

import CButton from './RootElement.vue'
import CLabel from './CLabel.vue'
import CDescriptor from './CDescriptor.vue'
import InputElement from './RootInput.vue'
import BaseSelect from './CSelect.vue'
import Checker from './Checker'
import PhoneInput from './PhoneInput.vue'

const { d: $d } = i18n.global
const DATE_CONFIG = 'datetime'

function baseFormatFabric(d: any, config = DATE_CONFIG) {
  return {
    stringify: (date: Date) => (date ? d(date, config) : ''),
    parse: (date: string) => {
      const newDate = d(new Date(), config)
      if (newDate.includes('/')) {
        return new Date(date)
      }
      if (newDate.includes('.')) {
        return new Date(date.split('.').reverse().join('/'))
      }
      return new Date(date)
    },
  }
}
function DatePickerBase(
  props: Record<string, unknown>,
  { emit, slots, attrs }: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>
): VNode {
  const {
    formatFabric = baseFormatFabric,
    innerElement = 'date-picker',
    dateFormat = '',
    format = '',
  } = props
  return h(
    InputElement,
    {
      ...props,
      format,
      formatter:
        typeof formatFabric == 'function' ? formatFabric($d, dateFormat) : null,
      ...attrs,
      // innerElement: attrs.component,
    },
    slots
  )
}

const wrp =
  (
    tag: string,
    blockName: string,
    root: any = InputElement
    // root: typeof InputElement = InputElement
  ) =>
  (
    props: Record<string, unknown>,
    ctx: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>
  ): VNode => {
    return h(
      root,
      { ...props, ...ctx.attrs, innerElement: tag, blockName },
      ctx.slots
    )
  }
type Context = { slots: unknown; emit: unknown; attrs: Record<string, unknown> }
function hoc(WrappedComponent: any, params: Record<string, unknown>) {
  return h(WrappedComponent, {
    ...params,
  })
}

export const CInput = wrp('input', 'control-input')
export const CTextarea = wrp('textarea', 'control-textarea')
export const CPhone = wrp('phone-input', 'control-phone')
export const CSelect = wrp('vue-multiselect', 'control-select', BaseSelect)

export const CCheckbox = hoc(Checker, { b: 'control-checkbox' })
export const CRadio = hoc(Checker, { b: 'control-radio' })

export const CDatePicker = hoc(DatePickerBase, {
  name: 'CDatePicker',
  blockName: 'control-date-picker',
  innerElement: 'date-picker',
  rangeSeparator: ' - ',
  lang: window.language,
  formatFabric: () => baseFormatFabric,
  dateFormat: DATE_CONFIG,
})

const dt =
  (tag: string, root = CDescriptor) =>
  (
    props: Record<string, unknown>,
    ctx: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>
  ): VNode =>
    h(root, { ...props, ...ctx.attrs, component: tag }, ctx.slots)

const DInput = dt('ControlInput')
const DTextarea = dt('ControlTextarea')
const DPhone = dt('ControlPhone')
const DSelect = dt('ControlSelect')
const DDatePicker = dt('ControlDatePicker')

export default {
  install(app: App<Element>): void {
    app
      .component('ControlButton', CButton)
      .component('PhoneInput', PhoneInput)
      .component('ControlField', CDescriptor)
      .component('ControlLabel', CLabel)
      .component('ControlInput', CInput)
      .component('ControlTextarea', CTextarea)
      .component('ControlPhone', CPhone)
      .component('ControlSelect', CSelect)
      .component('ControlCheckbox', CCheckbox)
      .component('ControlRadio', CRadio)
      .component('ControlDatePicker', CDatePicker)

      .component('DInput', DInput)
      .component('DTextarea', DTextarea)
      .component('DPhone', DPhone)
      .component('DSelect', DSelect)
      .component('DDatePicker', DDatePicker)
  },
}
