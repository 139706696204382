import { defineAsyncComponent, type App } from 'vue'
const LoginForm = defineAsyncComponent(() => import('./LoginForm.vue'))
const ResetPasswordForm = defineAsyncComponent(() => import('./ResetPasswordForm.vue'))
const SetPasswordForm = defineAsyncComponent(() => import('./SetPasswordForm.vue'))
const RegistrationForm = defineAsyncComponent(() => import('./RegistrationForm.vue'))
const VerifyPhoneForm = defineAsyncComponent(() => import('./VerifyPhoneForm.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('LoginForm', LoginForm)
      .component('ResetPasswordForm', ResetPasswordForm)
      .component('SetPasswordForm', SetPasswordForm)
      .component('RegistrationForm', RegistrationForm)
      .component('VerifyPhoneForm', VerifyPhoneForm)
  },
}
