import { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import { i18n } from '@/i18n'

const DASHBOARD_PREFIX = 'cabinet:dashboard'
const p = (name: string) => DASHBOARD_PREFIX + ':' + name

export const DASHBOARD_VIEW = p('view')

const DashboardView = () => import('./views/DashboardView.vue')

export function createRoutes(): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('', DashboardView, DASHBOARD_VIEW, {
      meta: {
        title: $t('Dashboard'),
      }
    })
  ]
}
