import { CreateAppFunction, App } from 'vue'
import { createPinia } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router'
import createAppRouter from '@/router'
import install from '@/install'

import { headerSticky } from '@utils/helpers'
import { aosInitiate } from '@utils/aos'

import Components from './components'
import Plugins from '@/plugins'
import Modules from '@/modules'
import Directives from '@/directives'

import i18n, { i18n as instanceI18n } from './i18n'

const { t } = instanceI18n.global

type CreateApplication = {
  createApp: CreateAppFunction<Element>
  createRouter?: typeof createRouter
  createWebHistory?: typeof createWebHistory
  createPinia?: typeof createPinia
}

export function createApplication({
  createApp,
  createRouter: createRouterInstance,
  createWebHistory,
  createPinia,
}: CreateApplication): App {
  const app = createApp({
    created() {
      headerSticky()
    },
    mounted() {
      setTimeout(() => {
        aosInitiate()
      }, 500);

      function iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      }
      if (iOS()) {
        const tag = document.createElement('meta')
        tag.setAttribute('name', 'viewport')
        tag.setAttribute('content', 'width=device-width, initial-scale=1.0,maximum-scale=1,user-scalable=0')
        document.head.appendChild(tag)
      }
    },
    // template: `<div>Some here testsd</div>`, // use template for SPA(if you want replace content in mounted #app(rootContainer)))
  })

  if (createPinia) {
    const pinia = createPinia()

    if (createRouterInstance && createWebHistory) {
      const router = createAppRouter({
        app,
        createInstance: createRouterInstance,
        createWebHistory,
        pinia,
      })
      app.use(router)
    }
    app.use(pinia)
  }

  app
    .use(i18n)
    .use(install)
    .use(Components)
    .use(Plugins)
    .use(Modules)
    .use(Directives)

  app.config.globalProperties.$log = console.log // you can use $log in template
  app.config.globalProperties.$goToTop = () =>
    document.body.scrollIntoView({ block: 'start', behavior: 'smooth' })
  app.config.globalProperties.$copyToClipboard = function copyToClipboard(
    value: string
  ) {
    // HTTPS ок localhost
    // use $toast after install it
    navigator.clipboard
      .writeText(value)
      .then(() => {
        this.$toast.success(t('Текст скопирован.'))
      })
      .catch(() => {
        this.$toast.success(t('Something went wrong.'))
      })
  }
  app.config.compilerOptions.delimiters = ['[[', ']]'] // use this delimiters in pug(jinja)-templates

  return app
}
