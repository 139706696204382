import type { App } from 'vue'
import General from './General'
import Controls from './Controls'
import Catalog from './Catalog'
import Modals from './Modals'

export default {
  install(app: App<Element>): void {
    app
      .use(General)
      .use(Controls)
      .use(Catalog)
      .use(Modals)
  },
}
