import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

import ModalTrigger from './ModalTrigger.vue'

const ConfirmLogout = defineAsyncComponent(() => import('./ConfirmLogout.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('ModalTrigger', ModalTrigger)
      .component('ConfirmLogout', ConfirmLogout)
  },
}
