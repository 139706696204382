import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

export interface FaqItem {
  pk: number
  question: string
  answer: string
}
export interface FaqCategory {
  pk: number
  title: string
  faqs: FaqItem[]
}

const FaqsContainer = defineAsyncComponent(() => import('./FaqsContainer.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('FaqsContainer', FaqsContainer)
  }
}