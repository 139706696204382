<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.53 9.47004L9.47004 14.53C8.82004 13.88 8.42004 12.99 8.42004 12C8.42004 10.02 10.02 8.42004 12 8.42004C12.99 8.42004 13.88 8.82004 14.53 9.47004Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998C8.46997 3.72998 5.17997 5.80998 2.88997 9.40998C1.98997 10.82 1.98997 13.19 2.88997 14.6C3.67997 15.84 4.59997 16.91 5.59997 17.77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.42004 19.5301C9.56004 20.0101 10.77 20.2701 12 20.2701C15.53 20.2701 18.82 18.1901 21.11 14.5901C22.01 13.1801 22.01 10.8101 21.11 9.40005C20.78 8.88005 20.42 8.39005 20.05 7.93005" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.47 14.53L2 22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 2L14.53 9.47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
