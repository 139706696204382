import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'
import UiElements from './UiElements'
import Auth from './Auth'
import Public from './Public'

const UserNotice = defineAsyncComponent(() => import('./UserNotice.vue'))
const UserBar = defineAsyncComponent(() => import('./UserBar.vue'))
const LanguageSwitcher = defineAsyncComponent(() => import('./LanguageSwitcher.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('LanguageSwitcher', LanguageSwitcher)
      .component('UserNotice', UserNotice)
      .component('UserBar', UserBar)
      .use(UiElements)
      .use(Auth)
      .use(Public)
  },
}
