import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'
import UiToggler from './UiToggler'
import UiAccordion from './UiAccordion.vue'
import AppIcon from './AppIcon.vue'
import Icons from './Icons'
import UiCarousel from './UiCarousel.vue'

const CodeConfirm = defineAsyncComponent(() => import('./CodeConfirm.vue'))
const UiEllipsisContent = defineAsyncComponent(() => import('./UiEllipsisContent.vue'))
const SocialShare = defineAsyncComponent(() => import('./SocialShare.vue'))
const UiSocialShareMin = defineAsyncComponent(() => import('./UiSocialShareMin.vue'))
const MapView = defineAsyncComponent(() => import('./MapView.vue'))
const AcceptSiteCookie = defineAsyncComponent(() => import('./AcceptSiteCookie.vue'))
const LoadingWrapper = defineAsyncComponent(() => import('./LoadingWrapper.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('UiToggler', UiToggler)
      .component('UiAccordion', UiAccordion)
      .component('AppIcon', AppIcon)
      .component('CodeConfirm', CodeConfirm)
      .component('UiEllipsisContent', UiEllipsisContent)
      .component('UiCarousel', UiCarousel)
      .component('SocialShare', SocialShare)
      .component('UiSocialShareMin', UiSocialShareMin)
      .component('MapView', MapView)
      .component('AcceptSiteCookie', AcceptSiteCookie)
      .component('LoadingWrapper', LoadingWrapper)

      .use(Icons)
  },
}
