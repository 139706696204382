import { markRaw, DefineComponent } from 'vue'
import { useModal } from 'vue-final-modal'
import BaseTemplate from '@/components/UiElements/Modals/BaseTemplate.vue'

import { vfm } from '@/plugins/vue-final-modal'

type MayBeAny = any

type FinishFn = (arg: MayBeAny) => void
type FinishPromise = (arg: MayBeAny) => Promise<MayBeAny> | Promise<void>
type Finish = FinishFn | FinishPromise

export default function useModalOpener(
  params: { component: DefineComponent, [key: string]: MayBeAny },
  finish: Finish = (arg: unknown) => undefined
): {
  open: () => Promise<string>
  close: () => Promise<string>
  patchOptions: (options: MayBeAny) => void
  destroy: () => void
} {
  const onFinish = (arg: unknown) => new Promise((resolve, reject) => {
    if (arg !== undefined) {
      resolve(arg);
      return;
    }
    reject();
  }).then(result => {
    finish(result);
    close()
  }).catch(err => {
    console.warn('use-modal-on-finish', err);
  });
  const { options, open, close, patchOptions, destroy } = useModal({
    component: markRaw(BaseTemplate),
    attrs: {
      onClose() {
        close()
      },
      onFinish(data: unknown) {
        onFinish(data)
      },
      clickToClose: true,
      escToClose: true,
      ...params,
    },
  })

  return { open, close, patchOptions, destroy }

}
