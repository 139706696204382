import { App } from 'vue'

import Auth from './auth'

export default {
  install(app: App<Element>): void {
    app
      .use(Auth)
  }
}
