import { renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["VueAgile"], _mergeProps($props.options, {
    ref: "carousel",
    unagile: $setup.isDisable,
    onBreakpoint: _cache[0] || (_cache[0] = ($event: any) => ($setup.checkAgileState()))
  }), {
    prevButton: _withCtx(() => [
      _renderSlot(_ctx.$slots, "prev-button", { carousel: $setup.carousel })
    ]),
    nextButton: _withCtx(() => [
      _renderSlot(_ctx.$slots, "next-button", { carousel: $setup.carousel })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["unagile"]))
}