import { partialRight } from 'ramda'
import { prefixAPI } from '@plugins/resource/api'
import { defineAsyncComponent, markRaw, ref } from 'vue'
import useModalOpener from '@composables/useModalOpener'

import {
  baseResource as superResource,
  jsonRequestMiddleware,
} from '@aspectus/resource'

import {
  xCSRFMiddleware,
  multipartRequestMiddleware,
  jsonTransformer,
  defaultDataTransformer,
  raiseHttpErrorTransformer,
  superagentJsonTransformer,
} from '@aspectus/resource-commons'

import makeTemplateGetter from '@aspectus/resource-template-url-getter'

import fetcher from '@aspectus/superagent-fetch'
import { HEADERS_MIDDLEWARE } from './middleware'

import UiMessageModal from '@components/UiElements/Modals/UiMessageModal.vue'

import { i18n as instanceI18n } from '@/i18n'

const { t } = instanceI18n.global

function handleServerError(response: Response) {
  if (500 === response.status) {
    const message = {
      title: t('Server error'),
      text: t('Api:') + " " + response.url,
    }

    const { open: openMessageModal } = useModalOpener({
      component: markRaw(UiMessageModal) as any,
      message,
    })
    
    openMessageModal()
    throw response
  }
  return response
}

export const baseResource = superResource
  .middleware(xCSRFMiddleware)
  .middleware(HEADERS_MIDDLEWARE)
  .transform(handleServerError)
  .transform(raiseHttpErrorTransformer)

export const receiveResource = baseResource
  .transform(jsonTransformer)
  .transform(defaultDataTransformer)

export const sendResource = baseResource
  .middleware(jsonRequestMiddleware)
  .transform(defaultDataTransformer)
  .transform(jsonTransformer)
  .config('method', 'POST')

export const sendFileResource = baseResource
  .fetcher(fetcher)
  .middleware(multipartRequestMiddleware)
  .transform(superagentJsonTransformer)
  .transform(defaultDataTransformer)
  .config('method', 'POST')

export const createResource = (
  template: string,
  base: typeof baseResource = baseResource
): typeof baseResource => base.url(makeTemplateGetter(template))
export const createReceiver = partialRight(createResource, [receiveResource])
export const createSender = partialRight(createResource, [sendResource])
export const createFileSender = partialRight(createResource, [sendFileResource])

export const r = (tpl: string, model = '', f: typeof baseResource = createReceiver) =>
  f(prefixAPI(tpl, model))
