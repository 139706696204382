import { defineAsyncComponent } from 'vue'
import type { App } from 'vue'

const UiPagination = defineAsyncComponent(() => import('./UiPagination.vue'))

export default {
  install(app: App<Element>): void {
    app.component('UiPagination', UiPagination)
  },
}
