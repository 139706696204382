import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: $setup.blockClasses }, { 'aria-disabled': $props.disabled }), [
    _createElementVNode("span", {
      class: _normalizeClass([`${$props.blockName}__body`])
    }, [
      _renderSlot(_ctx.$slots, "prepend"),
      ($props.startIcon || _ctx.$slots.startIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([`${$props.blockName}__element`, `${$props.blockName}__element--attachment_prepend`])
          }, [
            _renderSlot(_ctx.$slots, "startIcon", {}, () => [
              _createVNode(_component_AppIcon, {
                name: $props.startIcon,
                size: $props.iconSize
              }, null, 8, ["name", "size"])
            ])
          ], 2))
        : _createCommentVNode("", true),
      _createVNode($setup["VueMultiselect"], _mergeProps({
        class: ["element", [`${$props.blockName}__element`, `${$props.blockName}__element--primary`]],
        is: $props.innerElement
      }, {...$setup.defaultOptions, ..._ctx.$attrs}, {
        name: $props.name,
        readonly: $props.readonly,
        disabled: $props.disabled,
        id: $props.id
      }), _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (_, name) => {
          return {
            name: name,
            fn: _withCtx((bound) => [
              _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(bound || {})))
            ])
          }
        })
      ]), 1040, ["class", "is", "name", "readonly", "disabled", "id"]),
      ($props.endIcon || _ctx.$slots.endIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass([`${$props.blockName}__element`, `${$props.blockName}__element--attachment_append`])
          }, [
            _renderSlot(_ctx.$slots, "endIcon", {}, () => [
              _createVNode(_component_AppIcon, {
                name: $props.endIcon,
                size: $props.iconSize
              }, null, 8, ["name", "size"])
            ])
          ], 2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "append")
    ], 2)
  ], 16))
}