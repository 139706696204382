import VueGoogleMaps from '@fawmi/vue-google-maps';
import { vIntersectionObserver, vOnClickOutside } from '@vueuse/components';
import FloatingVue from 'floating-vue';
import { App, defineAsyncComponent } from 'vue';
import VueDatePicker from 'vue-datepicker-next';
import ToastPlugin from 'vue-toast-notification';
// import VChart from 'vue-echarts'

const MazPhoneNumberInput = defineAsyncComponent(() => import('maz-ui/components/MazPhoneNumberInput'))

import 'vue-datepicker-next/index.css';
require(`vue-datepicker-next/locale/en`);
if('ru' == window.language) {
  require(`vue-datepicker-next/locale/ru`);
}

export default {
  install(app: App<Element>): void {
    app
      .directive('on-click-outside', vOnClickOutside)
      .directive('intersection-observer', vIntersectionObserver)
    app
    // .component('VChart', VChart)
    .component('MazPhoneNumberInput', MazPhoneNumberInput)
    .use(ToastPlugin)
    .use(VueGoogleMaps, {
      load: {
        key: window.CONFIG.GOOGLE_MAPS_KEY || '',
        language: window.language,
      },
    })
      .use( VueDatePicker)
      .use(FloatingVue)
      
  },
}
