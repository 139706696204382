export function disableBeforeDate({ date = new Date(), include = true }) {
  return (d: number) => d < new Date(date).setHours(include ? 0 : 24, 0, 0, 0)
}

export function disableAfterDate({ date = new Date(), include = true }) {
  return (d: number) => d > new Date(date).setHours(include ? 0 : 24, 0, 0, 0)
}

export function headerSticky() {
  const { body } = document
  const scrollUpClass = 'scroll-up'
  const scrollDownClass = 'scroll-down'
  let lastScroll = 0
  const handleScroll = (): void => {
    const currentScroll = window.scrollY
    if (0 >= currentScroll) {
      body.classList.remove(scrollUpClass)
      return
    }

    if (
      currentScroll > lastScroll &&
      !body.classList.contains(scrollDownClass)
    ) {
      // down
      body.classList.remove(scrollUpClass)
      body.classList.add(scrollDownClass)
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDownClass)
    ) {
      // up
      body.classList.remove(scrollDownClass)
      body.classList.add(scrollUpClass)
    }
    lastScroll = currentScroll
  }
  window.addEventListener('scroll', handleScroll, { passive: true });
}
