<template>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<g clip-path="url(#clip0_209_14395)">
<path d="M18.3346 9.23355V10.0002C18.3336 11.7972 17.7517 13.5458 16.6757 14.9851C15.5998 16.4244 14.0874 17.4773 12.3641 17.9868C10.6408 18.4963 8.79902 18.4351 7.11336 17.8124C5.4277 17.1896 3.98851 16.0386 3.01044 14.5311C2.03236 13.0236 1.56779 11.2403 1.68603 9.44714C1.80427 7.65402 2.49897 5.94715 3.66654 4.58111C4.8341 3.21506 6.41196 2.26303 8.16479 1.867C9.91763 1.47097 11.7515 1.65216 13.393 2.38355" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3333 3.33398L10 11.6757L7.5 9.17565" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_209_14395">
<rect width="20" height="20" fill="currentColor"/>
</clipPath>
</defs>
</svg>
</template>