import { h, SetupContext, Fragment, VNode, DefineComponent, resolveDynamicComponent } from 'vue';

const name = 'control-checker';

interface Props {
  b: string
  tag: string
  type: string
  styling: string
  checked: boolean
  disabled: boolean
  invalid: boolean
  readonly: boolean
  modelValue: boolean
  id: string
}

export default function Checker(props: Record<string, unknown>, { emit, attrs }: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>): VNode {
  // name,

  // props: {
  //   b: { default: name },
  //   tag: { default: 'label' },
  //   type: { default: 'checkbox' },
  //   styling: { default: 'default' },
  //   checked: { type: Boolean, default: false },
  //   disabled: { type: Boolean, default: false },
  //   invalid: { type: Boolean, default: false },
  //   readonly: { type: Boolean, default: false },
  // },

  const {
    b = name,
    type = 'checkbox',
    styling = 'default',
    checked = false,
    disabled = false,
    invalid = false,
    readonly = false,
    id = Date.now()
  } = props

  return h(
    'label',
    // resolveDynamicComponent('tag') as DefineComponent,
    {
      tag: 'label',
      for: id,
      class: [
        b,
        {
          [`${b}--styling_${styling}`]: !!styling,
          [`${b}--type_${type}`]: !!type,
        },
        {
          'is-checked': checked,
          'is-disabled': disabled,
          'is-invalid': invalid,
          // checkbox doesn't have readonly attr
          'is-readonly': readonly,
        },
      ],
    },
    [
      h(
        'input',
        {
          id,  
          b: undefined,
          class: `${b}__element`,
          '^type': type,
          'checked': checked || null,
          '^disabled': disabled || null,
          'onChange': (e: Event) => { emit('update:modelValue', (e.target as HTMLInputElement).checked); },
          ...attrs,
        }
      ),
      h(
        'span',
        { class: `${b}__label` },
      ),
    ]
  )
}

Checker.props = [
  'b',
  'tag',
  'type',
  'checked',
  'disabled',
  'invalid',
  'readonly',
  'styling',
  'modelValue',
  'id',
]
Checker.emits = ['update:modelValue']

Checker.inheritAttrs = false
