import './public-path'

import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { createApplication } from '@/app'

import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue-final-modal/style.css'
import 'maz-ui/css/main.css'
import 'floating-vue/dist/style.css'
import './styles/index.sass'

const app = createApplication({
  createApp,
  createRouter,
  createWebHistory,
  createPinia,
})

app.mount('#app')


declare global {
  interface Window {
    language: string
    django: {
      catalog: {
        [key: string]: string
      }
    }
    isAuthenticated: string | number
    logout_url: string;
    activeLanguages: {
      code: string
      language_name: string
      value: string
      is_active: boolean
    }
    CONFIG: {
      GOOGLE_MAPS_KEY: string
      GOOGLE_API_KEY: string
      TIME_TO_RESEND_CODE: string
      GOOGLE_RECAPTCHA_KEY: string
      COOKIES: string
    }
    CTA: {
      image: string
      image_alt: string
      link: string
    }
    ACCESS_TOKENS: {
      MAP?: { [key: string]: string },
      ALL?: { [key: string]: string }
    }
  }
}