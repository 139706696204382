import type { App, DefineComponent, Plugin } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import { route, prefixRoutes } from '@router/utils'
import type { Options } from '@/router'

import { createRoutes as createProfileRoutes } from './modules/Profile/router'
import { createRoutes as createBalanceRoutes } from './modules/Balance/router'
import { createRoutes as createDashboardRoutes } from './modules/Dashboard/router'
import { createRoutes as createNotificationsRoutes } from './modules/Notifications/router'
import { createRoutes as createReferralRoutes } from './modules/Referral/router'
import { createRoutes as createTransactionsRoutes } from './modules/Transactions/router'

import { DASHBOARD_VIEW } from './routes'

function installer<T extends DefineComponent>(
  View: () => Promise<T>,
  Installer: () => Promise<T>,
  app: App<Element>
): () => Promise<Awaited<T>> {
  return function (): Promise<Awaited<T>> {
    return Promise.all([View(), Installer()]).then(function (result) {
      const [ViewComponent, installer] = result
      app.use(installer?.default || installer?.install || installer)
      return ViewComponent
    })
  }
}

const CabinetView = () => import('./views/CabinetView.vue')
const CabinetInstaller = () => import('./components')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { app } = options
  return [
    route(
      '',
      installer<any>(
        CabinetView,
        CabinetInstaller as unknown as () => Promise<Plugin>,
        app
      ),
      'cabinet',
      {
        children: [
          prefixRoutes('profile/', createProfileRoutes(options)),
          prefixRoutes('balance/', createBalanceRoutes()),
          prefixRoutes('dashboard/', createDashboardRoutes()),
          prefixRoutes('notification/', createNotificationsRoutes()),
          prefixRoutes('referral/', createReferralRoutes()),
          prefixRoutes('transactions/', createTransactionsRoutes()),
        ],
          redirect: { name: DASHBOARD_VIEW },
      }
    ),
  ]
}
