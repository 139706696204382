import { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import { i18n } from '@/i18n'

const BALANCE_PREFIX = 'cabinet:balance'
const p = (name: string) => BALANCE_PREFIX + ':' + name

export const BALANCE_VIEW = p('view')

const BalanceView = () => import('./views/BalanceView.vue')

export function createRoutes(): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('', BalanceView, BALANCE_VIEW, {
      meta: {
        title: $t('Balance'),
      }
    })
  ]
}
