import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "required-mark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.inputLabel)
    ? (_openBlock(), _createElementBlock("label", {
        key: 0,
        class: _normalizeClass(["control-descriptor__label", { 'control-descriptor__label--static': $props.staticLabel, 'control-descriptor__label--float': !$props.staticLabel, 'control-descriptor__label--active': $setup.isValue, [`control-descriptor__label--styling_${$setup.props.styling}`]: true }]),
        for: $props.id
      }, [
        _createTextVNode(_toDisplayString($props.inputLabel), 1),
        ($props.isRequired)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}