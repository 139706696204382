import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

const BlogCatalogView = defineAsyncComponent(() => import('./BlogCatalogView.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('BlogCatalogView', BlogCatalogView)
  }
}

