import { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import { i18n } from '@/i18n'

const REFERRAL_PROGRAM_PREFIX = 'cabinet:referrals'
const p = (name: string) => REFERRAL_PROGRAM_PREFIX + ':' + name

export const REFERRALS_VIEW = p('view')

const ReferralProgramView = () => import('./views/ReferralProgramView.vue')

export function createRoutes(): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('', ReferralProgramView, REFERRALS_VIEW, {
      meta: {
        title: $t('Referral program'),
      }
    })
  ]
}
