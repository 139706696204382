import type { App } from 'vue'
import Faqs from './Faqs'
import Form from './Form'
import Catalog from './Catalog'
import Order from './Order'

export default {
  install(app: App<Element>): void {
    app
      .use(Faqs)
      .use(Form)
      .use(Catalog)
      .use(Order)
  },
}
