import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

const AddToCard = defineAsyncComponent(() => import('./AddToCard.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('AddToCard', AddToCard)
  },
}
