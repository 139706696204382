import { SetupContext, defineComponent, ref, watch } from 'vue'
import { useToggle, useScrollLock } from '@vueuse/core'
export default defineComponent({
  props: {
    initialState: {
      type: Boolean,
      default: undefined,
    },
    scrollLock: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { slots, emit }: SetupContext) {
    const state = ref<boolean>(
      props.initialState !== undefined ? props.initialState : false
    )
    const scrollLock = useScrollLock(document.body)
    if (props.scrollLock) {
      watch(state, (isOpen: boolean) => {
        scrollLock.value = isOpen
      })
    }
    function collapse() {
      toggle(false)
      scrollLock.value = false
    }

    const toggle = useToggle(state)
    return () =>
      slots.default?.({
        state: state.value,
        toggle: () => {
          state.value = !state.value
          if (props.scrollLock) scrollLock.value = state.value
        },
        collapse,
      })
  },
})
