import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

const AuthProvider = defineAsyncComponent(() => import('./AuthProvider.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('AuthProvider', AuthProvider)
  }
}
