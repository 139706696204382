import { defineAsyncComponent } from 'vue'
import type { App } from 'vue'

const ContactForm = defineAsyncComponent(() => import('./ContactForm.vue'))
const ConsultationForm = defineAsyncComponent(() => import('./ConsultationForm.vue'))
const QuickRegistrationForm = defineAsyncComponent(() => import('./QuickRegistrationForm.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('ContactForm', ContactForm)
      .component('ConsultationForm', ConsultationForm)
      .component('QuickRegistrationForm', QuickRegistrationForm)
  }
}
