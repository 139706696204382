import { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'

const PROFILE_PREFIX = 'cabinet:profile'
const p = (name: string) => PROFILE_PREFIX + ':' + name

export const PROFILE_VIEW = p('view')
export const PROFILE_TWO_FACTOR = p('two-factor')
export const PROFILE_GOOGLE_AUTHENTICATOR = p('google-authenticator')
export const PROFILE_GOOGLE_AUTHENTICATOR_STEP_ONE = p(
  'google-authenticator:step-one'
)
export const PROFILE_GOOGLE_AUTHENTICATOR_STEP_TWO = p(
  'google-authenticator:step-two'
)
export const PROFILE_BACKUP_VERIFICATION_METHOD = p(
  'backup-verification-method'
)

const ProfileView = () => import('./views/ProfileView.vue')
const TwoFactor = () => import('./views/TwoFactor.vue')
const GoogleAuthenticator = () => import('./views/GoogleAuthenticator.vue')
const GoogleAuthenticatorStepOne = () =>
  import('./views/GoogleAuthenticatorStepOne.vue')
const GoogleAuthenticatorStepTwo = () =>
  import('./views/GoogleAuthenticatorStepTwo.vue')
const BackupVerificationMethod = () =>
  import('./views/BackupVerificationMethod.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('', ProfileView, PROFILE_VIEW, {
      meta: {
        title: $t('Profile'),
      },
    }),
    // prefixRoutes('two-factor/', [
    //   route('', TwoFactor, PROFILE_TWO_FACTOR, {
    //     meta: {
    //       title: $t('Security'),
    //     },
    //   }),
    //   route(
    //     'google-authenticator/',
    //     GoogleAuthenticator,
    //     PROFILE_GOOGLE_AUTHENTICATOR,
    //     {
    //       meta: {
    //         title: $t('2FA Settings'),
    //       },
    //       children: [
    //         route(
    //           'step-one/',
    //           GoogleAuthenticatorStepOne,
    //           PROFILE_GOOGLE_AUTHENTICATOR_STEP_ONE,
    //           {
    //             meta: {
    //               title: $t('2FA Settings'),
    //             },
    //           }
    //         ),
    //         route(
    //           'step-two/:id/',
    //           GoogleAuthenticatorStepTwo,
    //           PROFILE_GOOGLE_AUTHENTICATOR_STEP_TWO,
    //           {
    //             meta: {
    //               title: $t('2FA Settings'),
    //             },
    //           }
    //         ),
    //       ],
    //       redirect: { name: PROFILE_GOOGLE_AUTHENTICATOR_STEP_ONE },
    //     }
    //   ),
    //   route(
    //     'backup-verification/',
    //     BackupVerificationMethod,
    //     PROFILE_BACKUP_VERIFICATION_METHOD,
    //     {
    //       meta: {
    //         title: $t('2FA Settings'),
    //       },
    //     }
    //   ),
    // ]),
  ]
}
