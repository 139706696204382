<script>

import { defineComponent } from 'vue'
import useModalOpener from '@composables/useModalOpener'

export default defineComponent({
  name: 'ModalTrigger',
  props: {
    component: {
      type: [String, Object],
      default: () => null,
    },
    name: {
      type: String,
    },
    classes: {
      type: Array,
    },
    clickToClose: {
      default: true,
    },
  },
  setup(props, { slots, attrs }) {
    const { open } =
      useModalOpener(
        {
          ...props,
          ...attrs,
        }
      )

    return () =>
      slots.default({
        open: () => {
          open()
        },
      })
  },
})
</script>
