import { App, defineAsyncComponent } from 'vue';

// const PaginatedResourceController = defineAsyncComponent(() => import('./PaginatedResourceController.vue'));
const InfiniteResultsCollector = defineAsyncComponent(() => import('./InfiniteResultsCollector'));
const InfiniteTemplateResultsCollector = defineAsyncComponent(() => import('./InfiniteTemplateResultsCollector'));
const ResourceLoaderController = defineAsyncComponent(() => import('./ResourceLoaderController'));
// const PaginationController = defineAsyncComponent(() => import('./PaginationController'));
// const DelayedExecutorController = defineAsyncComponent(() => import('./DelayedExecutorController'));
const UrlParametersController = defineAsyncComponent(() => import('./UrlParametersController'));
const AppRenderer = defineAsyncComponent(() => import('./AppRenderer.vue'))


export default {
  install(app: App<Element>): void {
    app
      // .component('PaginatedResourceController', PaginatedResourceController)
      .component('InfiniteResultsCollector', InfiniteResultsCollector)
      .component('InfiniteTemplateResultsCollector', InfiniteTemplateResultsCollector)
      .component('ResourceLoaderController', ResourceLoaderController)
      // .component('PaginationController', PaginationController)
      // .component('DelayedExecutorController', DelayedExecutorController)
      .component('UrlParametersController', UrlParametersController)
      .component('AppRenderer', AppRenderer)
  }
};
