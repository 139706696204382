import { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import { i18n } from '@/i18n'

const NOTIFICATIONS_PREFIX = 'cabinet:notifications'
const p = (name: string) => NOTIFICATIONS_PREFIX + ':' + name

export const NOTIFICATIONS_VIEW = p('view')

const NotificationsView = () => import('./views/NotificationsView.vue')

export function createRoutes(): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('', NotificationsView, NOTIFICATIONS_VIEW, {
      meta: {
        title: $t('Notifications'),
      }
    })
  ]
}
