import type { App } from 'vue'

import Resource from './resource'
import Validation from './validation/plugin'
import VueFinalModal from './vue-final-modal'

export default {
  install(app: App<Element>): void {
    app
      .use(Validation)
      .use(VueFinalModal)
      .use(Resource)
  }
}