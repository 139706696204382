import type { App } from 'vue'

import {
  Form,
  Field,
  // FieldArray,
  ErrorMessage,
  defineRule,
  configure,
} from './index'

import {
  url,
  confirmed,
  email,
  length,
  numeric,
  size,
  min,
  max,
  min_value,
  max_value,
} from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import {
  isNullOrUndefined,
  isEmptyArray,
} from './rules-helper'

const MOBILEREG = /^[+()-\d]+$/

export type FormValidator = InstanceType<typeof Form>

export default {
  async install(app: App<Element>): Promise<void> {
    const { $t } = app.config.globalProperties

    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    // app.component('VeeFieldArray', FieldArray)
    app.component('VeeError', ErrorMessage)

    defineRule('required', (value: string | unknown[] | boolean) => {
      if (
        isNullOrUndefined(value) ||
        isEmptyArray(value) ||
        value === false ||
        !value
      ) {
        return $t('This field is required')
      }
      return true
    })
    defineRule('password', (value: string) => {
      if (!value || !value.length) {
        return $t('This field is required')
      }
      if (7 > value.length) return $t('Min length 8')

      const PASSWORD_REG = /^(?=.*?[A-ZА-Я])(?=.*?[a-zа-я])(?=.*?[0-9]).*$/
      if (!PASSWORD_REG.test(value)) {
        return $t(
          'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number.'
        )
      }

      return true
    })
    defineRule('email', (value: unknown | unknown[]) => {
      return email(value) || $t('This field must be a valid email')
    })
    defineRule(
      'confirmed',
      (value: unknown, params: [string] | { target: string }) => {
        return confirmed(value, params) || $t('Passwords must match')
      }
    )

    defineRule(
      'min',
      (value: unknown, params: [string | number]): boolean | string =>
        min(value, params) || $t('Value must be longer than ') + params[0]
    )

    defineRule(
      'max',
      (value: unknown, params: [string | number]): boolean | string =>
        max(value, params) || $t('Value must be shorter than ') + params[0]
    )
    defineRule('email_phone', (value: string): boolean | string => {
      // Regex for a international phone number
      const EMAILREG =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return (
        EMAILREG.test(value) ||
        MOBILEREG.test(value) ||
        $t('Set correct phone or email')
      )
    })

    defineRule('numeric', numeric)
    defineRule('length', length)
    defineRule('url', url)
    defineRule('size', size)
    defineRule(
      'min_value',
      (value: unknown, params: [string | number]): boolean | string =>
        min_value(value, params) ||
        $t('The field must be minimum') + ' ' + params[0]
    )
    defineRule(
      'max_value',
      (value: unknown, params: [string | number]): boolean | string =>
        max_value(value, params) ||
        $t('The field must be maximum') + ' ' + params[0]
    )

    const { language: lang } = window
    await import(`@vee-validate/i18n/dist/locale/${lang}.json`)
      .then((dictionary) => {
        configure({
          generateMessage: localize({ [lang]: dictionary }) as any,
        })
        setLocale(lang)
      })
      .catch(() => {
        console.warn(`Vee-Validate: Locale - ${lang} was not found!`)
      })
  },
}
